<template>
  <v-main
    :anchor-layout="layoutName"
    v-if="appReady"
    class="provider-store-layout d-print-p-0"
  >
    <liff-header v-model="showSidebar" class="no-print"></liff-header>

    <section v-if="layoutReady">
      <section v-if="layoutProviderId">
        <router-view v-if="showPage"></router-view>

        <!-- 須認證頁面且LINE未登入(非LIFF模式開啟) -->
        <div
          v-if="!isLogin"
          class="d-flex flex-column align-center justify-center py-10"
        >
          <div class="mb-6">
            <v-img src="~@/assets/linerp_logo_b.svg" width="230"> </v-img>
          </div>
          <div class="mb-4">
            <v-btn v-if="!isLiffClient" depressed @click="lineLogin" class="primary white--text">
              {{ "action.login_via_line" | t }}
            </v-btn>

            <v-btn v-if="isLiffClient" depressed @click="liffLogin" class="primary white--text">
              {{ "action.login_via_line" | t }}
            </v-btn>
          </div>

          <div v-if="false" class="my-4 red--text">
            {{ loginError }}
          </div>
        </div>
      </section>

      <!-- <div v-if="!layoutProviderId" class="red--text">
        供應商ID {{ routeProviderId }} 找不到對應供應商資料
      </div> -->
    </section>

    <fixed-cart v-if="showCartBtn" />
  </v-main>
</template>

<script lang="babel" type="text/babel">
import layoutMixins from "@/layout/layoutMixins";
import { version } from "@/../package.json";
import liff from "@line/liff";
import storage from "libs/storage/localStorage.js";
import Announce from '@/layout/announce.vue'
import Vue from "vue"
import dayjs from "dayjs"

export default {
  mixins: [layoutMixins],
  components: {
    "fixed-cart": () => import("components/buttons/fixedCart.vue"),
  },
  data: () => ({
    layoutName: "provider-store",
    isLiffLoggedIn: false,
    idToken: null,
    accessToken: null,
    appReady: false,
    loginError: null,
  }),
  watch: {
    layoutReady: {
      immediate: true,
      async handler() {
        if (!this.layoutReady) return;
        await this.init();
      },
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch("cart/setShowCart", true);
      },
    },
    // 進到頁面從未登入->登入, 要選擇商店身份
    async isLogin() {
      if (!this.isLogin) return;

      this.appReady = false;
      await this.selectStore();
      this.appReady = true;
    },
  },
  methods: {
    async init() {
      if (window.liff.isInClient() === true) {
        await this.setupLiff()
      }

      this.appReady = false;

      if (this.isLogin) {
        await this.readMemberProfile();
        await this.$nextTick();
      }

      // 由供應商角度 或 其他客戶查看其他供應商的角度來的, 必須清除token重新登入
      if (this.shouldClearToken) {
        window.tokenStore.clean();
        this.$store.dispatch("member/clean");
        this.$snotify.warning("畫面即將重新整理", "身份切換中");
        await this.$helper.delay(1);
        window.location.reload();
        return;
      }

      // 測試在 store-line-login-return 頁時不進行以下步驟
      if (this.$route.name === "store-line-login-return") {
        this.appReady = true;
        return;
      }
      if (this.isLogin) {
        this.checkStores()
      }
      this.appReady = true

      this.showAnnounce()
    },
    async showAnnounce(){
      const announce = await this.$api.collection.providerApi.getAnnounce(this.routeProviderId);
      const announceFromAdmin = await this.$api.collection.providerApi.getStoreAnnounce(this.routeProviderId);
      let providerAnnounce = announce.find((e)=>e.type==="PROVIDER_STORE")
      if(providerAnnounce) providerAnnounce = providerAnnounce.content
      let adminAnnounce = announceFromAdmin.find((e)=>e.type==="ADMIN_STORE")
      if(adminAnnounce) adminAnnounce = adminAnnounce.content
      const disableAnnounce = localStorage.getItem('disable_announce');
      if(!disableAnnounce || dayjs(disableAnnounce).endOf('day').valueOf() < dayjs().valueOf()){
        this.$apopup.base({
          title: "公告",
          content: '',
          close: false,
          bodySlot: Vue.component('announceBody', {
            render(createElement) {
              return createElement('div', [
                adminAnnounce ? createElement('div', { class: 'pb-5' }, [
                  createElement('h2',{ class: 'font-weight-bold' },'系統公告'),
                  createElement('div',adminAnnounce)
                ]) : null,
                providerAnnounce ? createElement('div', [
                  createElement('h2',{ class: 'font-weight-bold' },'商店公告'),
                  createElement('div',providerAnnounce)
                ]) : null,
              ].filter(Boolean));
            }
          }),
          actionSlot: Announce,
          applyCallback: () => null,
        })
      }
    },
    checkStores() {
      const routeName = this.$route.name
      if (
        routeName === 'store-register' ||
        routeName === 'store-line-login-return' ||
        routeName === 'store-product-list' ||
        routeName === 'store-product-detail'
      ) {
        return
      }
      if (!this.hasStore) {
        this.$snotify.success('請先進行註冊', '您尚未註冊餐廳')
        this.$router.replace({
          name: "store-register",
          params: {
            providerId: this.layoutProviderId,
          },
        })
        return
      }
    },
    async selectStore() {
      if (!this.hasStore) return;
      // 暫定取一家就好
      // await this.renewStoreToken(this.storeId)

      // await this.readStore()

      // this.$apopup.base({
      //   title: '請選擇要操作的商店',
      //   bodySlot: () => import('./storeSelector.vue'),
      //   applyCallback: async(store_id) => {
      //     await this.renewStoreToken(store_id)
      //   },
      //   persistent: true,
      //   close: false,
      //   stores
      // })
    },
    async renewStoreToken(store_id) {
      this.$store.dispatch("loading/active");
      try {
        const result = await this.$api.collection.baseApi.renewStoreToken(
          this.routeProviderId,
          store_id,
          { force: true }
        );
        this.$tokenStore.set(result.token);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async setupLiff() {
      const defaultLineLiffId = storage().get("chooseliffId");

      const lineLiffId =
        this.layoutProvider && this.layoutProvider.line_liff_id
          ? this.layoutProvider.line_liff_id
          : defaultLineLiffId;

      if (!lineLiffId) {
        console.warn("liff id不存在，無法初始化LIFF");
        this.$snotify.warning("畫面即將重新整理", "liff id不存在，無法初始化LIFF");
        await this.$helper.delay(1);
        window.location.reload();
        return;
      }

      await window.liff.init({
        liffId: lineLiffId,
      });

      await this.$helper.delay(0.2);

      this.isLiffLoggedIn = await window.liff.isLoggedIn();

      this.idToken = window.liff.getIDToken();
      this.accessToken = window.liff.getAccessToken();

      if (this.isLogin) await this.checkPivot();
    },
    async readStore() {
      this.$store.dispatch("loading/active");
      try {
        const res = await this.$api.collection.storeApi.readByProviderAndStore(
          this.storeId,
          this.routeProviderId
        );
        this.$store.dispatch(`store/setStore`, res);
      } catch (err) {
        this.$store.dispatch(`store/setDefault`);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async checkPivot() {
      try {
        const params = {
          credential: this.accessToken,
          id_token: this.idToken,
          keep_login: true,
          redirectUri: "/",
        };
        await this.$api.collection.storeApi.checkPivot(
          this.layoutProviderId,
          params
        );
      } catch (err) {}
    },
    async lineLogin() {
      this.$root.gaLogEvent("商店_點擊_登入");
      if (!this.layoutProvider.line_login_channel_id) {
        console.warn(
          `供應商channel id未設定`,
          this.layoutProvider.line_login_channel_id
        );
      }
      const host = `${window.location.origin}`;
      const href = this.$router.resolve({
        name: "store-line-login-return",
        params: {
          providerId: this.layoutProviderId,
        },
      }).href;

      this.$oauth.login({
        provider: "line",
        clientKey: this.layoutProvider.line_login_channel_id,
        successPath: this.$route.fullPath,
        keepLogin: true,
        isDebugMode: true,
        returnUri: `${host}${href}`,
      });
    },
    /**
     * 透過liff登入(使用id token + access token)
     */
    async liffLogin() {
      let params = {
        credential: this.accessToken,
        keep_login: true,
        id_token: this.idToken,
        redirectUri: "/identity/switch",
      };
      try {
        const result = await this.$api.collection.baseApi.storeLineOauthLogin(
          this.layoutProviderId,
          params
        );
        this.$tokenStore.set(result.token);
        this.$helper.account.login(this.rememberEmail);
        this.$store.dispatch(`member/set`, result.profile);
      } catch (error) {
        console.error(error);
        this.loginError = error;
      } finally {
      }
      window.location.reload()
    },
    reload() {
      window.location.reload();
    },
  },
  computed: {
    isLiffClient() {
      if (!window.liff) return false
      return window.liff.isInClient() === true
    },
    showCartBtn() {
      return this.$store.getters["cart/show"];
    },
    routeProviderId() {
      return this.$route.params.providerId;
    },
    version() {
      return version;
    },
    isPublicRoute() {
      return this.$route.meta.public;
    },
    showPage() {
      if (this.isPublicRoute) return true;
      return this.isLogin === true;
    },
    shouldClearToken() {
      if (!this.isLogin) return false;
      if (
        this.tokenRole &&
        this.tokenRole != this.$tokenRoleConstant.ROLE_STORE
      ) {
        return true;
      }
      return false;
    },
    tokenRole() {
      return this.$store.getters["token/tokenRole"];
    },
    providerIdFromStorage() {
      return this.$store.getters["member/providerId"];
    },
    layoutProviderId() {
      return this.$store.getters["base/layoutProviderId"];
    },
    layoutProvider() {
      return this.$store.getters["base/layoutProvider"];
    },
    redirectPath() {
      return this.$route.query.path || "/";
    },
    isLogin() {
      return this.$store.getters["token/isLogin"];
    },
    oauthClient() {
      return this.$store.getters["base/oauthClient"];
    },
    hasStore() {
      return this.$store.getters["member/hasStore"];
    },
    memberStores() {
      return this.$store.getters["member/memberStores"];
    },
    storesQuantity() {
      return this.memberStores.length
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    liffState() {
      return this.$route.query[`liff.state`]
    },
    liffId() {
      if (!this.layoutProvider) return null
      return this.layoutProvider.line_liff_id
    }
  },
};
</script>
