<template>
    <div class="d-inline-flex align-center">
        <v-checkbox id="disable-for-today" v-model="checked"/>
        <label for="disable-for-today" class="text-subtitle-2 grey--text text--grey-lighten-1 ">今日不再顯示</label>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  data: function () {
    return {
        checked: false,
        today: dayjs().format('YYYY-MM-DD')
    }
  },
  watch: {
    checked: {
      deep: true,
      handler() {
        if(this.checked === true){
          localStorage.setItem('disable_announce',this.today)
        }else{
          localStorage.removeItem('disable_announce')
        }
      },
    },
  }
}
</script>

<style scoped>

</style>